<template>
    <section class="oportunidades-informacion px-3">
        <navbar-admin>
            <button v-if="showFinalizarOportunidad" class="btn btn-general f-12" @click="finalirOp">
                Finalizar oportunidad
            </button>
        </navbar-admin>
        <tabs :tabs="tabsOportunidades" />
        <router-view />
        <modal-finalizar-op ref="modalFinalizarOp" />
    </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    components:{
        modalFinalizarOp: () => import('./partials/modalFinalizarOp')
    },
    data(){
        return{
            rutaFS: process.env.VUE_APP_URL_FULL_SMART,
            idOpportunity: this.$route.params.id,
            titulo:''
        }
    },
    computed: {
        ...mapGetters({
            oportunidadesInformacion: 'oportunidades/oportunidades/oportunidadesInformacion',
        }),
        showFinalizarOportunidad(){
            if (Object.values(this.oportunidadesInformacion).length && this.oportunidadesInformacion.estado_final == null) return true
            return false
        },
        tabsOportunidades(){
            return [
                {
                    titulo:'Información',
                    callback: () => window.open(`${this.rutaFS}/oportunidades/ver/${this.$route.params.id}/informacion`, "_self"),
                },
                {
                    titulo:'Bitácora',
                    callback: () => window.open(`${this.rutaFS}/oportunidades/ver/${this.$route.params.id}/bitacora/anio`, "_self"),
                },
                {
                    titulo:'Actas',
                    callback: () => window.open(`${this.rutaFS}/oportunidades/ver/${this.$route.params.id}/actas`, "_self"),
                },
                {
                    titulo:'Ciclos',
                    callback: () => window.open(`${this.rutaFS}/oportunidades/ver/${this.$route.params.id}/ciclos`, "_self"),
                },
                {
                    titulo:'Flujo de caja',
                    callback: () => window.open(`${this.rutaFS}/oportunidades/ver/${this.$route.params.id}/flujo-caja`, "_self"),
                },
                {
                    titulo:'Forecast',
                    callback: () => window.open(`${this.rutaFS}/oportunidades/ver/${this.$route.params.id}/forecast`, "_self"),
                },
                {
                    titulo:'Notas digitales',
                    callback: () => window.open(`${this.rutaFS}/oportunidades/ver/${this.$route.params.id}/notas-digitales`, "_self"),
                },
                {
                    titulo:'Comentarios',
                    callback: () => window.open(`${this.rutaFS}/oportunidades/ver/${this.$route.params.id}/comentarios`, "_self"),
                },
                {
                    titulo:'Lecciones aprendidas',
                    callback: () => window.open(`${this.rutaFS}/oportunidades/lecciones-aprendidas/ganadas`, "_self"),
                },
                {
                    titulo:'Equipos',
                    ruta:"oportunidades.ver.productos",
                },
                {
                    titulo:'Cotizaciones',
                    ruta:"oportunidades.ver.cotizaciones",
                }
            ]
        },
    },
    watch: {
        oportunidadesInformacion:{
            handler(val){
                if(Object.keys(val).length){
                    this.spliceBreadcumbs([
                        {
                            position: 1,
                            breadcumb: {
                                name: val.nombre_oportunidad,
                                route: 'oportunidades.ver',
                                params: { id: val.id },
                            }
                        }
                    ])
                }
            },
            deep: true
        },  
        "$route.name"(route){
            this.replaceBreadcumb({position: 2, breadcumb: route })
        }
    },
    async created(){
        this.getBreadcumbs(['oportunidades.main']);
        this.replaceBreadcumb({position: 1, breadcumb: this.$route.name })
        await this.Action_get_opportunity_information(this.idOpportunity)
    },
    methods:{
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb',
            Action_get_opportunity_information: 'oportunidades/oportunidades/Action_get_opportunity_information',
        }),
        finalirOp(){
            this.$refs.modalFinalizarOp.toggle()
        }
    },
}
</script>